import React, { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import {getAreaScope} from "../../actions";
import {useSelector} from "react-redux";

/**
 * Partner State Selector - on mount fetches all partners cities
 * @component
 * @alias PartnerLocationSelector
 * @property {number} initialValue - init value for select (state_id)
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Item required - default false
 * @returns <Form.Item /> containing <Select />
 */
const PartnerLocationSelector = ({ initialValue, required, readOnly, sendOnchange = null, clearable = false,}) => {
  const { t } = useTranslation();
  const areaScope = useSelector(getAreaScope);
  const [cities, setCities] = useState([]);
  const [selectedValues, setSelectedValues] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const setData = () => {
    let citiesArray = [];
    const additionalLocations = t('partners.additionalLocations', { returnObjects: true });
    const countries = areaScope?.cities;
    if (countries.length) {
      countries.map(country => {
        country.city_branches.map(city => {
          if (additionalLocations[city.name] !== undefined) {
            Object.values(additionalLocations[city.name]).map(item => {
              citiesArray.push({
                name:city.name + ' ' + item,
                value:city.name + ' ' + item,
              });
            })
          } else {
            citiesArray.push({
              name:city.name,
              value:city.id,
            });
          }
        })
      })
    }
    setCities(citiesArray);
  }

  const checkData = () => {
    if (!cities.length) setData();
  }

  useEffect(() => {
    setData();
  }, []);
  return (
    <Form.Item
      name="preferred_location"
      label={t("partners.selectPartnerLocation")}
      rules={[
        {
          required: required,
          message: t("partners.validation.state"),
        },
      ]}
      initialValue={initialValue}
      style={{minWidth: '400px'}}
      onClick={checkData}
    >
      <Select
        disabled={readOnly}
        value={selectedValues}
        allowClear={clearable}
        onFocus={() => setIsFocused(true)}
        onChange={(values) => {
          setSelectedValues(values);
          if (!isFocused) {
            if (sendOnchange) sendOnchange(values);
          }
        }}
        onBlur={() => {
          setIsFocused(false);
          if (sendOnchange) sendOnchange(selectedValues);
        }}
        showSearch={true}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        mode={"multiple"}
      >
        {cities.map((city) => (
          <Select.Option key={city.value} value={city.value}>
            {city.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PartnerLocationSelector;
